<template>
    <div>
        <el-form class="content" :model="loginForm" size="large">
            <el-tabs tab-position="top" v-model="acvtiveHPath" class="demo-tabs">
                <el-tab-pane style="margin: 16px;" label="登陆" name="1">
                    <div class="title">
                        <p>用户登录</p>
                    </div>
                    <el-input prefix-icon="el-icon-user" v-model="loginForm.username" clearable placeholder="用户名">
                    </el-input>
                    <el-input prefix-icon="el-icon-lock" v-model="loginForm.password" clearable show-password
                        placeholder="密码">
                    </el-input>
                    <div class="captcha" v-if="cc">
                        <el-input v-model="loginForm.code" clearable placeholder="验证码"></el-input>
                        <el-image :src="url" fit="fill" @click="Captcha()"></el-image>
                    </div>
                    <div class="content_button">
                        <el-button type="primary" @click="SignIn">登录</el-button>
                    </div>
                    <div id='login_yunbao'></div>
                </el-tab-pane>
                <el-tab-pane label="注册" name="2">
                    <el-input prefix-icon="el-icon-user" v-model="yq" clearable placeholder="宴请码">
                    </el-input>
                    <el-input prefix-icon="el-icon-user" v-model="loginForm.name" clearable placeholder="姓名">
                    </el-input>
                    <el-input prefix-icon="el-icon-user" v-model="loginForm.username" clearable placeholder="手机号">
                    </el-input>
                    <el-input prefix-icon="el-icon-lock" v-model="loginForm.password" clearable show-password
                        placeholder="密码">
                    </el-input>
                    <el-input prefix-icon="el-icon-lock" v-model="password2" clearable show-password placeholder="确认密码">
                    </el-input>
                    <div class="content_button">
                        <el-button type="primary" @click="register">注册</el-button>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-form>
    </div>
    <el-dialog v-model="DingTalk" title="登陆失败" width="50%" center :before-close="handleClose">
        <el-result icon="warning" title="自动登陆失败" sub-title="请关闭页面从工作台重新进入"> </el-result>
    </el-dialog>
    <div class="area">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
</template>

<script>
    import * as dd from 'dingtalk-jsapi';
    import {
        ElMessage
    } from 'element-plus'
    import {
        el
    } from 'element-plus/es/locale';
    import axios from "@/utils/request";
    import router from '@/router'
    export default {
        data() {
            return {
                cc: false,
                acvtiveHPath: '1',
                loginf: 'login',
                password2: '',
                yq: '',
                DingTalk: false,
                loginForm: {
                    username: '',
                    password: '',
                    code: '',
                    name: '',
                    uniqid: '',
                    fun: true
                },
                url: ''
            }
        },
        created() {
            const tokenStr = window.localStorage.getItem('token')
            const route = JSON.parse(window.localStorage.getItem('route'))
            if (tokenStr) return this.$router.push(`/${route[0].path}`)
            this.Captcha()
            if (dd.env.platform != 'notInDingTalk') {
                this.DingTalk = true
                let corpId = this.$route.query.corpId
                let then = this
                dd.runtime.permission.requestAuthCode({
                    corpId: corpId,
                    onSuccess: function(result) {
                        axios({
                            method: "post",
                            url: 'dlogin',
                            data: {
                                corpId: result.code,
                                uid: '',
                                upup: true,
                                fun: true
                            },
                        }).then(res => {
                            window.localStorage.setItem('token', res.token)
                            window.localStorage.setItem('jurisdiction', JSON.stringify(res
                                .jurisdiction))
                            window.localStorage.setItem('route', JSON.stringify(res.route));
                            then.$router.push(`/${res.route[0].path}`)
                        })
                    },
                    onFail: function(err) {}
                })
            }
        },
        methods: {
            register() {
                if (this.yq != 'mlgz8686') {
                    ElMessage({
                        type: 'error',
                        message: '宴请码不正确',
                    })
                    return
                }
                if (this.password2 != this.loginForm.password) {
                    ElMessage({
                        type: 'error',
                        message: '两次密码不一致',
                    })
                    return
                }
                this.loginForm.fun = false
                axios({
                    method: "post",
                    url: 'login',
                    data: this.loginForm,
                }).then(res => {
                    this.loginForm.fun = true
                    if (res.length != 0) {
                        this.Storageis(res)
                    } else {
                        this.cc = true
                        this.loginForm.code = ''
                        this.Captcha()
                    }
                })
            },
            SignIn() {
                axios({
                    method: "post",
                    url: 'login',
                    data: this.loginForm,
                }).then(res => {
                    if (res.length != 0) {
                        this.Storageis(res)
                    } else {
                        this.cc = true
                        this.loginForm.code = ''
                        this.Captcha()
                    }
                })
            },
            Storageis(res) {
                ElMessage({
                    type: 'success',
                    message: "成功！",
                })
                window.localStorage.setItem('token', res.token)
                window.localStorage.setItem('jurisdiction', JSON.stringify(res.jurisdiction))
                window.localStorage.setItem('route', JSON.stringify(res.route));
                this.$router.push(`/${res.route[0].path}`)
            },
            Captcha() {
                // 获取验证码
                axios({
                    method: "get",
                    url: 'Hcaptcha',
                }).then(res => {
                    this.url = res.url
                    this.loginForm.uniqid = res.uniqid
                })
            },
            handleClose() {
                window.sessionStorage.clear()
                window.localStorage.clear()
                window.location.reload()
                dd.quitPage({
                    success: () => {},
                    fail: () => {},
                    complete: () => {},
                });
                console.log('关闭页面')
            }
        }
    };
</script>

<style scoped less>
    .el-header,
    .el-footer {
        background-color: #171e49;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .el-menu {
        background-color: #171e49;
        z-index: 2;
    }

    * {
        padding: 0;
        margin: 0;
    }

    .content {
        width: 500px;
        height: 500px;
        box-sizing: border-box;
        padding: 20px 50px;
        border-radius: 5px;
        box-shadow: 0px 2px 12px 0px rgba(105, 105, 105, 0.07);
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: mymove 1s ease-in-out alternate;
        overflow: hidden;
        transition: 1.5s;
        z-index: 2;
    }

    @keyframes mymove {
        0% {
            width: 0px;
            height: 5px;
        }

        10% {
            width: 50px;
            height: 5px;
        }

        15% {
            width: 100px;
            height: 5px;
        }

        20% {
            width: 150px;
            height: 5px;
        }

        25% {
            width: 200px;
            height: 5px;
        }

        30% {
            width: 250px;
            height: 5px;
        }

        35% {
            width: 300px;
            height: 5px;
        }

        40% {
            width: 350px;
            height: 5px;
        }

        45% {
            width: 450px;
            height: 5px;
        }

        50% {
            width: 500px;
            height: 5px;
        }

        55% {
            height: 30px;
        }

        60% {
            height: 60px;
        }

        65% {
            height: 90px;
        }

        70% {
            height: 120px;
        }

        75% {
            height: 150px;
        }

        80% {
            height: 180px;
        }

        85% {
            height: 210px;
        }

        90% {
            height: 240px;
        }

        95% {
            height: 240px;
        }

        100% {
            height: 300px;
        }
    }

    .content_input {
        width: 300px;
        /* position: absolute; */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    .content_button .el-button {
        height: 40px;
    }

    .el-input {
        margin-bottom: 25px;
    }

    .title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 30px;
        font-weight: bold;
        color: #606266;
    }

    .el-button--primary {
        width: 100%;

    }

    .captcha {
        text-align: center;
    }

    .captcha .el-input {
        float: left;
        width: 100px;
    }

    .captcha .el-image {
        margin-left: 6px;
        height: 40px;
        width: 128px;
    }

    .captcha .el-button {
        margin-left: 5px;
    }

    .refresh {
        background-color: transparent;
    }

    .el-menu-item {
        width: 100px;
    }

    #login_yunbao {
        background: url("../assets/svg/yunbao-19.svg");
        width: 100%;
        height: 100%;
        position: fixed;
        background-repeat: no-repeat;
        background-position: 150px 0px;
        background-size: 45%;
    }

    /* 背景动画 */
    .area {
        width: 100%;
        height: 93.4vh;
        z-index: 1;
    }

    .circles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
    }

    .circles li {
        position: absolute;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        background: rgba(100, 149, 237, 0.4);
        animation: animate 25s linear infinite;
        bottom: -150px;

    }

    .circles li:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
    }


    .circles li:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
    }

    .circles li:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
    }

    .circles li:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
    }

    .circles li:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
    }

    .circles li:nth-child(6) {
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
    }

    .circles li:nth-child(7) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
    }

    .circles li:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
    }

    .circles li:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
    }

    .circles li:nth-child(10) {
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }

    @keyframes animate {
        0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
        }

        100% {
            transform: translateY(-1000px) rotate(720deg);
            opacity: 0;
            border-radius: 50%;
        }
    }

    /* 下面是背景 */
</style>